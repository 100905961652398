<template>
<div id="body">
  <div class="container form">
		<div class="card card0 mx-5">
			<div class="d-flex flex-lg-row flex-column-reverse">
				<div class="row justify-content-center my-auto mx-auto">
						<div class="col-md-12 col-12 my-2 mx-4">

              <!-- {{query}} -->
							<h3 class="text-center heading">Reset Password</h3>
							<h6 class="msg-info text-center">Create your new password.</h6>

              <form @submit.prevent="submit">
							  <div class="form-group">
                  <span class="fa left fa-lock"></span>
                  <input
                    @input="checkPassword"
                    placeholder="Password"
                    :type="showPassword ? 'text' : 'password'"
                    v-model="password"
                    name="password"
                    autocomplete="new-password"
                    class="form-control input-auth"
                    required
                  />
                  <span
                    :class="[!showPassword ? 'fa-eye' : 'fa-eye-slash', 'fa']"
                    @click="showPassword = !showPassword">
                  </span>
                </div>
							  <div class="form-group">
                  <span class="fa left fa-lock"></span>
                  <input
                    @input="checkPassword"
                    placeholder="Confirm Password"
                    :type="showRePassword ? 'text' : 'password'"
                    v-model="password_repeat"
                    autocomplete="new-password"
                    class="form-control input-auth"
                    required
                  />
                  <span
                    :class="[!showRePassword ? 'fa-eye' : 'fa-eye-slash', 'fa']"
                    @click="showRePassword = !showRePassword">
                  </span>
                </div>
							
                <small>Password Must:</small>
                <ul>
			            <li v-bind:class="{ is_valid: contains_eight_characters }">Have at least 8 characters.</li>
			            <li v-bind:class="{ is_valid: contains_number }">Have at least 1 number (1, 2, 3, ...).</li>
			            <li v-bind:class="{ is_valid: contains_uppercase }">Have at least 1 uppercase letter (A, B, C, ...).</li>
			            <li v-bind:class="{ is_valid: contains_special_character }">Have at least 1 special character (*, #, @, ...).</li>
                  <li v-bind:class="{ is_valid: password_match }">Match repeated password.</li>
		            </ul>
                <br>

							  <div class="row justify-content-center my-3 px-3">
                  <button class="btn btn-primary text-uppercase" :disabled="!valid_password">
                    change password <CSpinner grow color="100" size="sm" v-if="loading"/>
                  </button>
                </div>

                <div class="row justify-content-center my-2">
                <small>
                  <a style="cursor: pointer;" @click="toLogin">Back to Sign In</a>
                </small>
              </div>

                <br>
                <CAlert
                  color="danger"
                  closeButton
                  v-if="showMessage"
                >
                  {{message}}
                </CAlert>

              </form>

              <!-- <img src="@/assets/icons/logo-footer.png" class="img-fluid my-auto mx-auto px-2 py-4" /> -->

            </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import http from '@/utils/http-common';

export default {
  name: 'ResetPassword',
  data () {
    return {
      page: window.location.href,
      loading: false,
      showMessage: false,
      message: '',
      password: '',
      password_repeat: '',
      showPassword: false,
      showRePassword: false,
      password_match: false,
      password_length: 0,
		  contains_eight_characters: false,
      contains_number: false,
      contains_uppercase: false,
		  contains_special_character: false,
      valid_password: false
    }
  },
  props: {
    query: {
      default: '',
      type: String,
      required: false
    },
  },
  methods: {
    toLogin() {
      this.$router.push({ name: 'Login' })
    },
    checkPassword() {
      this.password_length = this.password.length;
			const format = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
			
      if (this.password_length > 8) {
        this.contains_eight_characters = true;
      } else {
        this.contains_eight_characters = false;
			}

      if (this.password.length > 0 && this.password === this.password_repeat) {
        this.password_match = true;
      } else {
        this.password_match = false;
      }
			
      this.contains_number = /\d/.test(this.password);
      this.contains_uppercase = /[A-Z]/.test(this.password);
			this.contains_special_character = format.test(this.password);
      
      if (this.password_match === true && 
        this.contains_eight_characters === true &&
				this.contains_special_character === true &&
				this.contains_uppercase === true &&
				this.contains_number === true) {
					this.valid_password = true;			
      } else {
        this.valid_password = false;
      }
    },
    checkMatch() {
      if (this.password.length > 0 && this.password === this.password_repeat) {
        this.password_match = true;
      } else {
        this.password_match = false;
      }

      if (this.password_match === true) {
				this.valid_password = true;			
      } else {
        this.valid_password = false;
      }
    },
    validEmail: function (email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    submit () {
      let self = this;
      self.loading = true;
			return http.post('/change-password', {
				// email: 'kayagila.id@gmail.com',
        password: self.password,
        password_confirmation: self.password_repeat,
        passwordToken: self.query,
			})
			.then(function () {
				self.loading = false;
        self.$router.push({ name: 'Login' })
			}).catch(function (err) {
				self.message = 'Either your email or token is wrong.';
        self.showMessage = true;
        self.loading = false;
        console.log(err)
			});
    }
  },
  mounted: function(){
		console.log(this.page)
	}
}
</script>
<style lang="scss">
  // Import custom styles for this application
  @import '@/assets/css/style';
</style>
<style scoped>
ul {
	padding-left: 20px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}
li { 
	margin-bottom: 8px;
	color: #ff5656;
	position: relative;
}
li:before {
  content: "";
	width: 0%; height: 2px;
	background: #2ecc71;
	position: absolute;
	left: 0; top: 50%;
	display: block;
	transition: all .6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.is_valid {
  color: rgba(97, 207, 69, 0.8); 
}
.is_valid:before {
  width: 100%; 
}
@keyframes draw {
  to { stroke-dashoffset: 0; }
}
</style>
